import React, { useState, useEffect } from 'react'
import {graphql, StaticQuery} from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Emailer = () => {
  
  const [inputs, setInputs] = useState({});
  const [messageClasses, setMessageClasses] =  useState('');

  const [response, setResponse] = useState({active: false, status: '', message: ''});
  
  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }

  const handleSubmit = async (event) => {
    event.persist();
    event.preventDefault();
    const result = await addToMailchimp(inputs.email)
    await setResponse({active: true, ...result})
    if (response.result === 'error') {
      setMessageClasses('border-red-500')
    } else {
      setMessageClasses('border-green-500')
    }

  } 

  return (
    <StaticQuery query={query} render={props => {
      let emailer = props.emailer
      return ( emailer.show ? (
        <div className="flex flex-wrap mb-16 md:mb-24">
          <form onSubmit={handleSubmit} className="mailer w-full bg-primary text-white px-8 md:px-16 py-12 flex flex-wrap items-center text-center md:text-left">
            <div className="w-full md:w-2/5 mb-4 md:mb-0">
              {emailer.title && <h3 className="font-bold text-2xl mb-2">{emailer.title}</h3> }
              {emailer.text && <p className="font-light mb-0">{emailer.text}</p> }
            </div>
            <div className="w-full md:w-3/5 flex flex-wrap items-center">
              <div className="mailer__input w-full md:w-2/3 h-full md:pr-8"><input onChange={handleInputChange} value={inputs.email} type="email" name="email" required /></div>
              <button className="btn transition w-full md:w-1/3 bg-black inline-block rounde-sm text-center px-2 py-5 text-white border-none hover:shadow-lg hover:bg-white hover:text-black cursor-pointer">Sign Up</button>
            </div>
          </form>
          <div class={`${response.active ? 'block' : 'hidden'} border-2 border-solid ${messageClasses} w-full p-4 md:p-8 text-center`} dangerouslySetInnerHTML={{__html: response.msg}}></div>
        </div>
      ) : <span key={i}/>
    )}} />
  )
}

export default Emailer

const query = graphql`
  {
    emailer : contentfulEmailSignup(id: {eq: "769250c3-7dee-5664-8c04-a09cc4a175b4"}) {
      show
      text
      title
    }
  }
`
